.live-header{
    background-color:#F67C0C ;
    h4{
        color: #fff;
    }
    
}
.question-no{
    
    
}

.question-card-row { 
    min-height: 20rem;
    margin-bottom: 10px;
    overflow-y: scroll;
    overflow: visible;
  }
  .question-card-col{ 
      border: 1px solid gray;
      height: 15rem;
     min-height: inherit;
    overflow-y: scroll;
  }
  .live-exam-num-btn{
      border: 1px solid;
  }