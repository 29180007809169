.pcoded-navbar:not(.theme-horizontal) .navbar-content {
  height: calc(100% - 70px);
}

.card {
  .card-header+.collapse {
    >.card-body {
      padding-top: 0;
    }
  }
}

.pcoded-navbar {
  .pcoded-inner-navbar {
    li {
      >a.active {
        color: #e5efff;
        /*font-weight: 600;*/
      }


      .pcoded-submenu>li {

        &.active,
        &:focus,
        &:hover {

          >a,
          >a.active {
            &:before {
              background: #e5efff;
            }
          }
        }

        >a.active {
          &:before {
            background: #e5efff;
          }
        }
      }
    }

    >li {

      &.active,
      &.pcoded-trigger {

        >a,
        >a.active {
          color: #e5efff;
        }
      }

    }

    >.pcoded-menu-caption {

      &.active,
      &.pcoded-trigger {
        &:after {
          display: none;
        }
      }
    }
  }

  &.menu-light {
    .pcoded-inner-navbar {
      li {
        >a.active {
          color: $primary-color;
          background: transparent;

          &:before {
            background: $primary-color;
          }
        }

        .pcoded-submenu>li {

          >a.active {
            &:before {
              background: $primary-color;
            }
          }
        }

      }
    }
  }
}

.search-bar {
  display: block;
}

table.dataTable.table-condensed .sorting:after,
table.dataTable.table-condensed .sorting_asc:after,
table.dataTable.table-condensed .sorting_desc:after {
  top: 16px;
}

table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
  font-family: feather !important;
  content: "\e82a";
}

table.dataTable thead .sorting_asc:after {
  content: "\e82d";
}

table.dataTable thead .sorting_desc:after {
  content: "\e82a";
}

.css-1bm1z97-ToastContainer {
  top: 70px !important;

  .alert-inverse .close {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.pcoded-navbar.menu-light.theme-horizontal .pcoded-inner-navbar>li.pcoded-hasmenu.active:before,
.pcoded-navbar.menu-light.theme-horizontal .pcoded-inner-navbar>li.pcoded-hasmenu.pcoded-trigger:before {
  color: transparent;
  text-shadow: none;
}

@media (max-width: 1199px) {
  .reverse-md {
    flex-direction: column-reverse;
  }
}

@media (max-width: 767px) {
  .reverse-sm {
    flex-direction: column-reverse;
  }
}

@media (max-width: 499px) {
  .reverse-xs {
    flex-direction: column-reverse;
  }
}

.custom-background {
  background: linear-gradient(40deg, #8876e2, #1f3148, #1fcce2);
  background-size: 600% 600%;

  -webkit-animation: bgGradient 19s ease infinite;
  -moz-animation: bgGradient 19s ease infinite;
  animation: bgGradient 19s ease infinite;
}

@-webkit-keyframes bgGradient {
  0% {
    background-position: 0% 33%
  }

  50% {
    background-position: 100% 68%
  }

  100% {
    background-position: 0% 33%
  }
}

@-moz-keyframes bgGradient {
  0% {
    background-position: 0% 33%
  }

  50% {
    background-position: 100% 68%
  }

  100% {
    background-position: 0% 33%
  }
}

@keyframes bgGradient {
  0% {
    background-position: 0% 33%
  }

  50% {
    background-position: 100% 68%
  }

  100% {
    background-position: 0% 33%
  }
}

.progress-animate {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 0.6rem 0.6rem;
}

.progress.muted .progress-bar {
  background-color: #8d8d8d;
}

.examCred {
  padding: 10px 10px 1px 10px;
  background-color: #FF9700;
  color: #e5efff !important;
  border-radius: 5px;
}



// calculator

.result {
  height: 60px;
  background-color: #bbb;
  width: 100%;
}

.result p {
  font-size: 40px;
  margin: 5px;

}

.calculator-body {
  max-width: 400px;
  margin: auto;
}

.button {
  display: block;
  background-color: #bbb;
}

.calculator-btn button {
  width: 25%;
  height: 60px;
  font-size: 30px;
}

//loader 
.page-loader {
  height: 100vh;
  width: 100vh;

  div {
    position: absolute;
    top: 50%;
    left: 50%
  }
}


.card-body1 {
  height: 55vh,
}


.col2 {
  padding: 5px;
  padding-left: 2px;
  padding-top: 0px;
  width: 80%;
}



.card2 {
  height: 68vh;
}

.common {
  height: 66vh;
}

@media only screen and (max-width:2560px) {
  .card-body1 {
    height: 64vh,
  }

  .common {
    height: 67vh;
  }
}

@media only screen and (max-width:1440px) {
  .card-body1 {
    height: 56vh,
  }

  .col2 {
    margin-top: 5px;
  }

  .card2 {
    height: 68vh;
  }

  .common {
    height: 66vh;
  }
}

@media only screen and (max-width:769px) {
  .card-body1 {
    height: 55vh,
  }

  .col2 {
    height: 65vh;
  }

  .common {
    height: 66vh;
  }

  .white-box {
    height: 2.4rem;
    width: 2.4rem;
    background-color: white;
    border: 1px solid #e8dede;
  }

  .green-box {
    height: 2.4rem;
    width: 2.4rem;
    background-color: #70ad51;
  }

  .yellow-box {
    height: 2.4rem;
    width: 2.4rem;
    background-color: #f9a100;
  }

  .blue-box {
    height: 2.4rem;
    width: 2.6rem;
    background-color: rgb(64, 149, 255);
  }
}

@media only screen and (max-width:765px) {
  .card-body1 {
    height: 55vh,
  }

  .col2 {
    margin-top: 30px;
    height: 68vh;
  }

  .common {
    height: 66vh;
  }

  .white-box {
    height: 1.4rem;
    width: 1.4rem;
    background-color: white;
    border: 1px solid #e8dede;
  }

  .green-box {
    height: 1.4rem;
    width: 1.4rem;
    background-color: #70ad51;
  }

  .yellow-box {
    height: 1.4rem;
    width: 1.4rem;
    background-color: #f9a100;
  }

  .blue-box {
    height: 1.4rem;
    width: 1.6rem;
    background-color: rgb(64, 149, 255);
  }
}

.notAttempt {
  margin: 0px;
  font-size: 11px; //15px
  font-weight: bold
}

.attempt {
  margin: 0px;
  font-size: 11px;
  font-weight: bold
}

.tagged {
  margin: 0px;
  font-size: 11px;
  font-weight: bold
}

.tag-ans {
  margin: 0px;
  font-size: 11px;
  font-weight: bold
}

.white-box {
  height: 1.4rem;
  width: 1.4rem;
  background-color: white;
  border: 1px solid #e8dede;
}

.green-box {
  height: 1.4rem;
  width: 1.4rem;
  background-color: #70ad51;
}

.yellow-box {
  height: 1.4rem;
  width: 1.4rem;
  background-color: #f9a100;
}

.blue-box {
  height: 1.4rem;
  width: 1.6rem;
  background-color: rgb(64, 149, 255);
}



.ques-box {
  height: 1.5rem;
  margin: 3px;
  font-weight: 500;
  width: 50%;
}

.exam-name {
  font-weight: 550px;
}


.card-head {
  margin: 5px 0px 0px 0px;
  padding: 10px 0px 0px 0px;
}

.card-mid {
  margin: 0px 0px 0px 0px;
  padding: 14px 10px 2px 10px;
}

.card-bottom {
  margin: 0px 0px 5px 0px;
  padding: 10px 0px 2px 0px;
}


.instruction {
  padding-right: 1rem;
  position: relative;
}

.notification {
  background-color: red;
}


// .pic{
//   height: 9rem;
//   width:100%;
// }
.video {
  // height: 210px;
  height: 210px;
  width: 90%;
  max-width: 280px;

}

.captured-img {
  height: 10rem;
}

.img-capture {
  width: 90%;
  max-width: 280px;
}

.capture-img-instructions {
  min-width: 400px;
}

.val-page-header {
  color: black;
  font-weight: bold;
  text-align: center;
}

#container {
  background-color: transparent;
}

@media only screen and (max-width: 375px) {
  .size {
    width: 2.2rem;
  }
}

@media only screen and (max-width: 375px) {
  .dLogo-size {
    width: 2.1rem;
  }
}

@media only screen and (min-width: 375px) and (max-width: 400px) {
  .dLogo-size {
    width: 2.7rem;
  }
}

@media only screen and (min-width: 401px) and (max-width: 768px) {
  .dLogo-size {
    width: 3.2rem;
  }
}

@media only screen and (max-width: 767px) {
  .candDetailNav {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.candDetailNav {
  background: white !important;
}

@media only screen and (min-width: 580px) and (max-width: 767px) {
  .val-exam-details {
    margin-top: 2rem;
    // margin-left: 1.7rem;
  }
}

@media only screen and (max-width: 767px) {
  .box {
    margin-bottom: 15px;
  }
}

.card-border {
  border: 1px solid rgba(197, 197, 197, 0.918);
}

.card-border-bottom {
  border-bottom: 1px solid rgba(197, 197, 197, 0.918);
}

@media only screen and (max-width: 1024px) {
  .subAnswer {
    max-width: 90%;
  }
}



/*.flag-shape {
   Main square color 
clip-path: path("M 0 0 H 500 V 41 H 0 Z M 500 10 H 200 A 10 10 0 1 0 200 30 H 500 Z")}*/
.loading-strip {
  height: 6px;

}

.form-check-label>p {
  margin: 0px;
}

.subAnswer {
  min-height: 300px;
}

.instructions-button:hover {
  background-color: #1f3148 !important;
}

.container-fluid>.form-control {
  padding: 22px 12px !important;
}

.instruction-li>p {
  font-size: 17px !important;

}

code {
  background-color: #f5f2f0;
  /* Light background to highlight code */
  color: #d63384;
  /* Syntax-like color */
  font-family: 'Courier New', Courier, monospace;
  /* Monospaced font for code */
  padding: 0.2em 0.4em;
  /* Padding for better spacing */
  border-radius: 4px;
  /* Rounded corners */
  font-size: 0.9em;
  /* Slightly smaller text */
  border: 1px solid #e1e1e1;
  /* Light border for definition */
  white-space: pre-wrap;
  /* Allow wrapping of long code */
  width: 100%;
  display: block;
}

.ql-container {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  height: calc(100% - 80px) !important;
}

.ql-toolbar {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.no-padding {
  padding: 0px !important;
}

@media only screen and (min-width:1350px) {
  .position-lg-sticky {
    position: sticky;
  }
}

input[type=radio] {
  background-color: #fff;
  border: 1px solid #9e9e9e;
}

input[type="checkbox"] {
  background-color: #fff;
  border: 1px solid #9e9e9e;
}

.name-confirm-container {
  height: calc(100vh - 20px);
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}

.name-confirm-card {
  width: 500px;
  @media screen and (max-width:765px) {
    width: 390px;
  }
}
.avatar-circle{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width:50px;
  background-color: #45A163CC;
  border-radius: 50%;
  color: white;
  font-size: 1.7em;
  font-weight: 600;
}