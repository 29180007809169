/**  =====================
      Custom css start
==========================  **/
body {
    font-family: $theme-font-family;
    font-size: $theme-font-size;
    color: $theme-font-color;
    font-weight: 400;
    background: $theme-background;
    position: relative;
}

* {
    &:focus {
        outline: none;
    }
}

a {
    &:hover {
        outline: none;
        text-decoration: none;
    }
}

h1,
h2,
h3,
h4,
h5 {
    color: $theme-heading-color;
    font-weight: 500;
}

h1 {
    font-size: 40px;
}

h2 {
    font-size: 36px;
}

h3 {
    font-size: 24px;
}

h4 {
    font-size: 20px;
}

h5 {
    font-size: 18px;
}

h6 {
    color: $theme-heading-color;
    font-size: 16px;
}

p {
    font-size: 14px;
}

b,
strong {
    font-weight: 600;
}
.cursor-pointer{
    cursor: pointer;
}

.img-radius {
    border-radius: 50%;
}

.page-header {
    background: transparent;
    padding: 0;

    h5 {
        margin-bottom: 5px;
        font-weight: 500;
    }

    .page-header-title + .breadcrumb {
        background: transparent;
        padding: 0;
        margin-bottom: 0;

        > .breadcrumb-item {
            line-height: 1;

            a {
                color: $primary-color;
                font: {
                    weight: 400;
                }
            }

            &:last-child a {
                color: $theme-font-color;
                font-weight: 400;
            }
        }
    }

    &:not(.breadcumb-sticky) {
        .page-header-title + .breadcrumb {
            > .breadcrumb-item {
                a {
                    font: {
                        size: 13px;
                    }
                }
            }
        }
    }

    &.breadcumb-sticky {
        background: #fff;
        padding: 15px 25px;
        margin-top: -25px;
        margin-left: -25px;
        margin-right: -25px;
        box-shadow: 0 0px 3px 0 rgba(54, 80, 138, 0.3);

        .page-header-title {
            display: inline-block;

            h5 {
                margin-bottom: 0;
            }
        }

        .breadcrumb {
            display: inline-flex;
            float: right;
            margin-top: 4px;
            @media only screen and (max-width: 767px) {
                display: flex;
                float: none;
            }
        }
    }
}

.pcoded-main-container {
    .page-header {
        margin-bottom: 30px;
    }
}
/* ==========  card css start  =========== */

.anim-rotate {
    animation: anim-rotate 1s linear infinite;
}
@keyframes anim-rotate {
    100% {
        transform: rotate(360deg);
    }
}

.anim-close-card {
    animation: anim-close-card 1.4s linear;
}
@keyframes anim-close-card {
    100% {
        opacity: 0.3;
        transform: scale3d(.3, .3, .3);
    }
}
/* ==========  scrollbar End  =========== */

.scroll-y {
    z-index: 1027;
}
/* ==========  scrollbar End  ===========
 ==========  card css End  ===========
 ================================    Dropdown Start  ===================== */

.dropdown-menu {
    padding: 10px 0;
    font-size: $theme-font-size;

    > li,
    > .dropdown-item {
        padding-left: 15px;
        padding-right: 15px;
        width: auto;
        border-radius: 2px;

        > a {
            padding: 5px 15px;
            color: $theme-font-color;

            i {
                font-size: 15px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                margin-right: 10px;
            }
        }

        &.active,
        &:active,
        &:focus,
        &:hover {
            background: transparentize($primary-color,0.97);
            color: $primary-color;

            > a {
                background: transparent;
                color: $primary-color;
            }
        }
    }

    > a {
        &.active,
        &:active,
        &:focus,
        &:hover {
            background: transparentize($primary-color,0.97);
            color: $primary-color;
        }
    }
}
.drp-icon{
    &:after{
        display: none;
    }
}
/* ================================    Dropdown End  ===================== */
@media (min-width: 1600px) {
    .container {
        max-width: 1540px;
    }
}
@media (max-width: 992px) {
    .container {
        max-width: 100%;
    }
}
/* ========================================================
 ===============     document      ======================
 ========================================================

 Grid examples
*/

.bd-example-row {
    background: $theme-background;
    padding: 15px;
    margin-bottom: 15px;

    .row {
        > [class^="col-"],
         > .col {
            padding-top: 0.75rem;
            padding-bottom: 0.75rem;
            background-color: rgba(86, 61, 124, .15);
            border: 1px solid rgba(86, 61, 124, .2);
        }
    }

    .row + .row {
        margin-top: 1rem;
    }

    .flex-items-bottom,
    .flex-items-middle,
    .flex-items-top {
        min-height: 6rem;
        background-color: rgba(255, 0, 0, .1);
    }
}

.bd-example-row-flex-cols .row {
    min-height: 10rem;
    background-color: rgba(255, 0, 0, .1);
}
/* Docs examples */

.bd-example {
    position: relative;
    padding: 1rem;
    margin: 1rem;
    border: solid #ddd;
    border-width: 0.2rem 0 0;
    @media only screen and (max-height: 575px) {
        padding: 1.5rem;
        margin-right: 0;
        margin-left: 0;
        border-width: 0.2rem;
    }
}
/* Example modals */

.modal {
    z-index: 1072;

    .popover,
    .tooltip {
        z-index: 1073;
    }
}

.modal-backdrop {
    z-index: 1071;
}

.bd-example-modal {
    background-color: #fafafa;

    .modal {
        position: relative;
        top: auto;
        right: auto;
        bottom: auto;
        left: auto;
        z-index: 1;
        display: block;
    }

    .modal-dialog {
        left: auto;
        margin-right: auto;
        margin-left: auto;
    }
}
/* Example tabbable tabs */

.bd-example-tabs .nav-tabs {
    margin-bottom: 1rem;
}
/* Code snippets */

.highlight {
    padding: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    background-color: #f3f2fb;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    @media only screen and (max-height: 575px) {
        padding: 1.5rem;
    }
}

.bd-content .highlight {
    margin-right: 15px;
    margin-left: 15px;
    @media only screen and (max-height: 575px) {
        margin-right: 0;
        margin-left: 0;
    }
}

.highlight {
    pre {
        padding: 0;
        margin-top: 0;
        margin-bottom: 0;
        background-color: transparent;
        border: 0;
    }

    pre code {
        font-size: inherit;
        color: #333;
    }
}
:not(pre) > code[class*="language-"], pre[class*="language-"]{
    background: transparent;
}
/* =======================================================================
 ===============     Ui kit copy model style      ====================== */
/* Code examples */

.datta-example {
    position: relative;

    .datta-example-btns {
        position: absolute;
        right: 30px;
        top: -10px;
        opacity: 0;
        transform: rotateX(-90deg);
        transition: all 0.3s ease-in-out;

        .datta-example-btn {
            display: inline-block;
            line-height: 1;
            font-weight: 600;
            background: $primary-color;
            color: #fff;
            padding: 0.1875rem 0.3125rem;
            border-radius: 2px;
            white-space: nowrap;
            font-size: 11px;
            margin: 0 4px;

            &.copy::before {
                content: "COPY";
            }

            &.copied {
                background: $success-color !important;
                color: #fff !important;

                &::before {
                    content: "COPIED!";
                }
            }
        }
    }

    &:hover .datta-example-btns {
        top: -21px;
        transform: rotateX(0deg);
        opacity: 1;
    }
}
/* Modal */

.datta-example-modal {
    position: fixed;
    z-index: 1099;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: #282c34;
    transform-origin: 50% 0;
    transform: scale(0.0);
    transition: all 0.3s ease-in-out;
}

.datta-example-modal-opened {
    overflow: hidden !important;

    .datta-example-modal {
        transform: scale(1);
    }
}

.datta-example-modal-content {
    max-width: 100vw;
    margin: auto;
    padding: 50px;
    height: 100vh;
    overflow: auto;

    > pre {
        overflow: hidden;
        width: fit-content;

        > code {
            padding: 0;
            background: none;
            font-size: 16px;
        }
    }
}

.md-datta-example-modal-copy {
    display: block;
    position: fixed;
    top: 90px;
    right: 30px;
    margin-right: 8px;
    font-size: 1.5rem;
    border-radius: 50%;
    padding: 10px 15px;
    background: $primary-color;
    color: #fff;
    box-shadow: 0 10px 20px 0 rgba(0,0,0,0.2);
    z-index: 1;
    white-space: nowrap;

    &:hover {
        box-shadow: 0 10px 20px 0 rgba(0,0,0,0.2);
        color: #fff;
    }

    &.copied::before {
        content: "Copied to Clipboard Successfully ! . . .";
        position: absolute;
        display: block;
        right: 100%;
        margin-right: 10px;
        font-size: 14px;
        background: $success-color;
        line-height: 24px;
        height: 24px;
        border-radius: 3px;
        padding: 0 6px;
        top: 50%;
        margin-top: -12px;
    }
}

.datta-example-modal-close {
    display: block;
    position: fixed;
    top: 10px;
    right: 52px;
    color: #fff;
    opacity: 0.2;
    font-size: 3rem;
    font-weight: 100;
    transition: all 0.3s ease-in-out;
    z-index: 1;

    &:hover {
        color: #fff;
        opacity: 0.9;
    }
}
/* Code */
.cui-bottom-spacer {
    height: 12rem;
}
/* editor style for model */
.hljs {
    padding: 0.5em;
    color: #abb2bf;
    background: #282c34;
}

.hljs-comment,
.hljs-quote {
    color: #5c6370;
    font-style: italic;
}

.hljs-doctag,
.hljs-formula,
.hljs-keyword {
    color: #c678dd;
}

.hljs-deletion,
.hljs-name,
.hljs-section,
.hljs-selector-tag,
.hljs-subst {
    color: #e06c75;
}

.hljs-literal {
    color: #56b6c2;
}

.hljs-addition,
.hljs-attribute,
.hljs-meta-string,
.hljs-regexp,
.hljs-string {
    color: #98c379;
}

.hljs-built_in,
.hljs-class .hljs-title {
    color: #e6c07b;
}

.hljs-attr,
.hljs-number,
.hljs-selector-attr,
.hljs-selector-class,
.hljs-selector-pseudo,
.hljs-template-variable,
.hljs-type,
.hljs-variable {
    color: #d19a66;
}

.hljs-bullet,
.hljs-link,
.hljs-meta,
.hljs-selector-id,
.hljs-symbol,
.hljs-title {
    color: #61aeee;
}

.hljs-emphasis {
    font-style: italic;
}

.hljs-strong {
    font-weight: bold;
}

.hljs-link {
    text-decoration: underline;
}
/* ================================    md animated mode start   ===================== */

.md-show.md-effect-12 ~ .md-overlay {
    background: $primary-color;
}
/* ================================    md animated mode End   ===================== */
/* ================================    range slider Start  ===================== */

.tooltip {
    &.in {
        opacity: 0.9;
    }

    .tooltip-arrow {
        position: absolute;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
    }

    &.top {
        padding: 5px 0;
        margin-top: -3px;

        .tooltip-arrow {
            bottom: 0;
            left: 50%;
            margin-left: -5px;
            border-width: 5px 5px 0;
            border-top-color: #000;
        }
    }
}
/* ================================    range slider End  ===================== */
/* ================================    owl-carousel slider Start  ===================== */

.owl-carousel {
    .owl-nav {
        button {
            &.owl-next,
            &.owl-prev {
                padding: 3px 9px;
            }
        }
    }

    button.owl-dot {}
}
/* ================================    owl-carousel slider End  ===================== */
/* ================================    Bootstrap tags input Start  ===================== */

.bootstrap-tagsinput {
    padding: 10px 15px;
    box-shadow: none;
    border-radius: 2px;
    background: $form-bg;

    .tag {
        padding: 5px 12px;
        border-radius: 2px;
        line-height: 37px;
        margin-top: 5px;
        margin-right: 5px;

        [data-role="remove"] {
            margin-right: -5px;

            &:after {
                content: "\e8f6";
                padding: 0 2px;
                font-family: 'feather' !important;
            }
        }
    }
}
/* ================================    Bootstrap tags input End  ===================== */
/* ================================    Multi-Select Start  ===================== */

.ms-container {
    width: 100%;
}
/* ================================    Multi-Select End  ===================== */
/* ================================    Blockquote Start  ===================== */

.blockquote {
    border-left: 0.25rem solid $theme-border;
    padding: 0.5rem 1rem;

    &.text-right {
        border-left: none;
        border-right: 0.25rem solid $theme-border;
    }
}
/* ================================    Blockquote End  ===================== */
/* ================================    animation start  ===================== */

.card .animated {
    animation-duration: 1s;
    border: 5px solid #fff;
    margin-bottom: 30px;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
}
/* ================================    animation End  ===================== */
/* ================================    browser  warning  Start  ===================== */

.ie-warning {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
    background: #000000;
    width: 100%;
    height: 100%;
    text-align: center;
    color: #fff;
    font-family: "Courier New", Courier, monospace;
    padding: 50px 0;

    p {
        font-size: 17px;
    }

    h1 {
        color: #fff;
    }

    .iew-container {
        min-width: 1024px;
        width: 100%;
        height: 200px;
        background: #fff;
        margin: 50px 0;
    }

    .iew-download {
        list-style: none;
        padding: 30px 0;
        margin: 0 auto;
        width: 720px;

        > li {
            float: left;
            vertical-align: top;

            > a {
                display: block;
                color: #000;
                width: 140px;
                font-size: 15px;
                padding: 15px 0;

                > div {
                    margin-top: 10px;
                }

                &:hover {
                    background-color: #eee;
                }
            }
        }
    }
}
/* ================================    browser  warning  End  ===================== */
div.code-toolbar {
    > .toolbar a,
     > .toolbar span,
    > .toolbar button {
        padding: 3px 0.9em !important;
        background: #04a9f5 !important;
        color: #fff !important;
        box-shadow: none !important;
    }
}

pre[class*=language-]:after,
pre[class*=language-]:before {
    display: none;
}
/* table css */

.table-borderless tbody tr td,
.table-borderless tbody tr th {
    border: 0;
}
@media only screen and (max-width: 400px) {
    .lay-customizer .theme-color > a[data-value="reset"],
    .theme-color > a[data-value="reset"] {
        margin-top: 20px;
    }
}
.form-control[type="file"]{
    height: auto;
}

// <uniquifier>: Use a unique and descriptive class name
// <weight>: Use a value from 100 to 900

.outfit-100 {
    font-family: "Outfit", sans-serif;
    font-optical-sizing: auto;
    font-weight: 100;
    font-style: normal;
}

.outfit-400 {
    font-family: "Outfit", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
}.outfit-600 {
    font-family: "Outfit", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
}
.outfit-800 {
    font-family: "Outfit", sans-serif;
    font-optical-sizing: auto;
    font-weight: 800;
    font-style: normal;
}
.text-gray-600{
    color: #575757;
}
.text-gray-300 {
    color: #a3a3a3;
}
.text-green-600 {
    color: #4BB543;
}
.text-red-600 {
    color: #DA3939;
}
.text-orange-600 {
    color: #F77D0D;
}
.bg-red{
    background-color: #DA3939;
}
.bg-red-600 {
    background-color: #DA3939;
}

.bg-green-600 {
    background-color: #4BB543;
}
.bg-green-700 {
    background-color: #007A5A;
}
.bg-green-300 {
    background-color: #C4E7C1;
}
.bg-orange-600 {
    background-color: #F77D0D;
}
.bg-orange-700 {
    background-color: #ff5722;
}
.bg-blue-600 {
    background-color: #1967D2;
}
.bg-blue-700 {
    background-color: #114082;
}
.bg-gray-300 {
    background-color: #E8E8E8;
}
.bg-gray-600 {
    background-color: #adadad;
}


.bg-green-900{
        background-color: #007A5A;
}

/* Width Classes */
.w-0 {width: 0;}
.w-1 {width: 0.25rem;}
.w-2 {width: 0.5rem;}
.w-3 {width: 0.75rem;}
.w-4 {width: 1rem;}
.w-5 {width: 1.25rem;}
.w-6 {width: 1.5rem;}
.w-8 {width: 2rem;}
.w-10 {width: 2.5rem;}
.w-12 {width: 3rem;}
.w-14 {width: 3.5rem;}
.w-16 {width: 4rem;}
.w-21 {width: 5rem;}
.w-24 {width: 6rem;}
.w-32 {width: 8rem;}
.w-41 {width: 10rem;}
.w-48 {width: 12rem;}
.w-56 {width: 14rem;}
.w-64 {width: 16rem;}
.w-72 {width: 18rem;}
.w-80 {width: 20rem;}
.w-auto {width: auto;}
.w-screen {width: 100vw;}
// x / 12
.w-1\/12 { width: 8.333333%; }
.w-2\/12 { width: 16.666667%; }
.w-3\/12 { width: 25%; }
.w-4\/12 { width: 33.333333%; }
.w-5\/12 { width: 41.666667%; }
.w-6\/12 { width: 50%; }
.w-7\/12 { width: 58.333333%; }
.w-8\/12 { width: 66.666667%; }
.w-9\/12 { width: 75%; }
.w-10\/12 { width: 83.333333%; }
.w-11\/12 { width: 91.666667%; }
.w-fit {width: fit-content;}
// x / 5
.w-1\/5 { width: 20%; }
.w-2\/5 { width: 40%; }
.w-3\/5 { width: 60%; }
.w-4\/5 { width: 80%; }
.w-full { width: 100%; }
.w-screen { width: 100vw; }
/* Height Classes */
.h-0 {height: 0;}
.h-1 {height: 0.25rem;}
.h-2 {height: 0.5rem;}
.h-3 {height: 0.75rem;}
.h-4 {height: 1rem;}
.h-5 {height: 1.25rem;}
.h-6 {height: 1.5rem;}
.h-8 {height: 2rem;}
.h-10 {height: 2.5rem;}
.h-12 {height: 3rem;}
.h-14 {height:  3.5rem;}
.h-16 {height: 4rem;}
.h-21 {height: 5rem;}
.h-24 {height: 6rem;}
.h-41 {height: 10rem;}
.h-48 {height: 12rem;}


.h-64 {height: 16rem;}

.h-fit {height:  fit-content;}
.v-gap-2{
    column-gap: 1rem;
}
.h-gap-2 {
    row-gap: 1rem;
}
.right-rounded-circle {
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
}
.left-rounded-circle {
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
}
.gap-0\.5{
gap:2px
}
.border-gray-500{
    border-color: #b7b7b7;
}
.border-gray-700 {
    border-color: #808080;
}
.border-green-700 {
    border-color: #4BB543 !important;
    box-shadow: 0px 0px 4px 0px #4bb543;
}
.border-red-700 {
    border-color: #DA3939 !important;
    box-shadow: 0px 0px 4px 0px #DA3939;
}
.h-full {
    height: 100%;
}
.h-screen {
    height: 100vh;
}
.transition {
    transition: all 1s;
}

@media only screen and (min-width:770px) {
.w-md-1\/12 { width: 8.333333% !important; }
.w-md-2\/12 { width: 16.666667% !important; }
.w-md-3\/12 { width: 25% !important; }
.w-md-4\/12 { width: 33.333333% !important; }
.w-md-5\/12 { width: 41.666667% !important; }
.w-md-6\/12 { width: 50% !important; }
.w-md-7\/12 { width: 58.333333% !important; }
.w-md-8\/12 { width: 66.666667% !important; }
.w-md-9\/12 { width: 75% !important; }
.w-md-10\/12 { width: 83.333333% !important; }
.w-md-11\/12 { width: 91.666667% !important; }
// x / 5
.w-md-1\/5 { width: 20% !important; }
.w-md-2\/5 { width: 40% !important; }
.w-md-3\/5 { width: 60% !important; }
.w-md-4\/5 { width: 80% !important; }
.fs-md-4 {font-size: 18px !important;}
}
@media only screen and (max-width:770px) {
    .h-screen {
        height: auto;
    }
    .w-12 {width: 2.5rem;}
    .h-12 {height: 2.5rem;}

}
.py-2\.5{
    padding-top:10px;
        padding-bottom: 10px;
}
@media only screen and (min-width:1200px) {
    .w-xl-4\/5 {
        width: 80% !important;
    }

    .w-xl-1\/5 {
        width: 20% !important;
    }
}
.custom-cont {
    border-radius: 0px;
    display: flex;
    box-shadow: none!important;
    align-items: center;
    background: transparent!important;

}
.max-w-600px{
    max-width:600px !important;
}

.max-w-900px {
    max-width: 900px !important;
}
.max-w-800px{
        max-width: 800px !important;
}
.max-w-1000px {
    max-width: 1000px !important;
}
.max-w-500px {
    max-width: 500px !important;
}
.max-h-535px {
    max-height: 535px !important;
}


@media only screen and (min-width:770px) {
.md\:min-w-600px {
        min-width: 600px !important;
    }

}
.flex-1{
    flex: 1;
}
.object-cover{
    object-fit: cover;
}